<template>
    <div class="background">
        <div id="fake-container">
            <b-img id="member-banner" src="../assets/Member_Banner.png"></b-img>
        </div>

        <!-- SEARCH HISTORY -->
        <template v-if="showsearch === 'yes'">
            <b-link id="member-search-history" @click="$bvModal.show('search-history-modal')">
                <font-awesome-icon icon="history"></font-awesome-icon>
            </b-link>
        </template>

        <div class="content" v-if="user.member_access === '1'">

            <!-- SEARCH HISTORY MODAL -->
            <b-modal no-fade id="search-history-modal">
                <template v-slot:modal-header="">
                    <b-col>
                        <b-row align-h="center">
                            Recently viewed members:
                        </b-row>
                    </b-col>


                </template>

                <template v-slot:default="">
                    <div v-if="memberhistory.length > 0" class="mb-2 max-100" align-h="center">
                        <div class="buttons" v-for="key in memberhistory" :key-value="key.alt_id" v-bind:key="key.alt_id">
                            <b-button block @click.prevent="memberReSelect">
                                <div class="non-clickable">{{ key.member_name_l }}, {{ key.member_name_f }}</div>
                            </b-button>
                        </div>
                     </div>
                     <div v-if="memberhistory.length === 0">
                         No members viewed.
                      </div>

                 </template>

                <template v-slot:modal-footer="">
                    <b-container fluid>
                        <a class="clear-search-history-link" href="#" @click.prevent="memberHistoryClear">
                            <div class="clear-search-history-button">Clear Search History</div>
                        </a>
                    </b-container>

                </template>
            </b-modal>

            <!-- SEARCH SSN -->
            <template>
                <form id="ssnSearch" v-if="this.showsearch === 'yes'" @submit.prevent="submitSSN">
                    <div class="input-group custominput">
                        <input id="ssnSearchInput" class="form-control" name="ssn-search"
                            type="text" placeholder="Search by SSN" v-model="form.searchedssn">
                        <div class="input-group-nest">
                            <button class="btn searchbutton" type="submit">
                                <font-awesome-icon icon="search"></font-awesome-icon>
                            </button>
                        </div>
                   </div>
                </form>
            </template>

            <!-- SEARCH NAME -->
            <template>
                <form id="nameSearch" v-if="this.showsearch === 'yes'" @submit.prevent="submitName">
                    <div class="input-group custominput">
                        <input id="nameSearchInput" class="form-control" name="searchedname"
                            type="text" placeholder="Search by Name"
                                v-model="form.searchedname">
                        <div class="input-group-nest">
                            <button class="btn searchbutton" type="submit">
                                <font-awesome-icon icon="search"></font-awesome-icon>
                            </button>
                        </div>
                   </div>
                </form>
            </template>



            <!-- ERROR MESSAGE -->
            <template>
                <b-row id="errormsg" v-if="error" align-h="center" class="max-100"><b>{{ error }}</b></b-row>
            </template>


            <!-- MEMBER SEARCH RESULTS -->
            <div v-if="foundmembers">
                <div id="foundmembers-header" align-h="center" v-if="foundmembers != ''" class="mb-2 max-100"><b>Select a member from the list below.</b></div>
                <div id="member-results">
                    <div id="member-results-container">
                        <div id="member-results-list">
                            <div class="buttons" v-for="member in foundmembers" v-bind:key="member.alt_id" :member-value="member.alt_id">
                                <b-button block @click.prevent="memberSelect">
                                    <div class="button-top-row">{{ member.member_name_f }} {{ member.member_name_m }} {{ member.member_name_l }}</div>
                                    <div class="button-bottom-row">{{ member.member_ss_no }}</div>
                                    <div v-if="member.member_status === 'TERMINATED'" class="button-bottom-row-red">{{ member.member_status }}</div>
                                    <div v-if="member.member_status != 'TERMINATED'" class="button-bottom-row-black">{{ member.member_status }}</div>
                                </b-button>
                            </div>

                            <div id="member-results-hidden-div"></div>

                        </div>
                    </div>
                </div>
                <div id="member-results-list-arrow-more">
                    <font-awesome-icon icon="long-arrow-alt-down"></font-awesome-icon>
                </div>

            </div>

            <div v-if="member && showsearch === 'no' && showmember === 'yes'">
                <!-- MEMBER NAME -->
                <div id="member-name-box">
                    <b-row align-v="center" align-h="center" class="name-container max-100">
                        <div class="member-name-dependents">
                            {{ member.member_name_f }}
                            {{ member.member_name_m }}
                            {{ member.member_name_l }}
                        </div>
                    </b-row>
                </div>

                <div id="member-ind-container">
                    <div id="member-top-box">
                        <b-row id="member-general-info" align-v="center">
                            <b-col cols="2" class="general-info-icon"><font-awesome-icon icon="info-circle"></font-awesome-icon></b-col>
                            <b-col id="general-info-label" cols="10" align-v="center" class="pl-0"><b>GENERAL INFO</b></b-col>
                        </b-row>

                        <!-- MEMBER DETAIL -->
                        <div id="member-card">
                            <div class="member-plate-data">
                                <div>SSN:<span class="inv">..............</span>{{ member.member_ss_no }}</div>
                                <div>DOB:<span class="inv">..............</span>{{ member.date_of_birth }}</div>
                                <div>Gender:<span class="inv">...........</span>{{ member.gender }}</div>
                                <div>Status:<span class="inv">...........</span>{{ member.member_status }}</div>
                                <div>Phone:<span class="inv">............</span>{{ member.telephone }}</div>
                                <div>Email:<span class="inv">............</span>{{ member.email }}</div>
                                <div>MaritalStatus:<span class="inv">....</span>{{ member.marital_staus }}</div>
                                <div>Date of Marriage:<span class="inv">.</span>{{ member.date_of_marriage }}</div>
                            </div>
                        </div>

                    </div>

                    <div id="member-address-box">
                        <b-row id="member-address-bar" align-v="center">
                            <b-col cols="2"><b-img class="address-icon" fluid src="../assets/Member_Page_Address.png"></b-img></b-col>
                            <b-col id="address-label" cols="10" align-v="center" class="pl-0"><b>ADDRESS</b></b-col>
                        </b-row>
                        <div id="member-address" v-if="member.address_1 != null">
                            <div>{{ member.address_1 }}</div>
                            <div v-if="member.address_2 != null">{{ member.address_2 }}</div>
                            <div>{{ member.city }}, {{ member.state }} {{ member.zip }}</div>
                        </div>
                        <div id="member-address" v-if="member.address_1 == null">
                                No address on file.
                        </div>
                    </div>

                    <div id="member-shop-box">
                        <b-row id="member-shop-bar" align-v="center">
                            <b-col cols="2"><b-img class="shop-icon" fluid src="../assets/Member_Page_ShopInfo.png"></b-img></b-col>
                            <b-col id="member-shop-label" cols="10" align-v="center" class="pl-0"><b>{{ member.employer_name }}</b></b-col>
                        </b-row>
                        <div id="member-shop">
                            <div>DOH: {{ member.date_of_hire }}</div>
                            <div>DOE: {{ member.date_of_elig }}</div>
                            <div>DOT: {{ member.date_of_term }}</div>
                            <div>PLAN DESC: {{ member.plan_desc }}</div>
                        </div>

                    </div>

                    <div id="member-photo-box">
                        <div id="member-photo-button">
                            <a href="#" @click.prevent="goPhoto">
                                <font-awesome-icon icon="camera-retro"></font-awesome-icon>
                            </a>
                        </div>
                        <div class="member-photo-container" v-if="member.member_picture != null">
                            <b-img class="member-photo" fluid v-bind:src="'data:image/jpeg;base64,'+member.member_picture"></b-img>
                        </div>
                        <div class="member-photo-container" v-if="member.member_picture == null">
                            <b-img class="no-photo" src="../assets/camera-nopicture.svg"></b-img>
                        </div>
                    </div>

                    <div id="member-ind-hidden-div"></div>

                </div>
                <div id="member-ind-list-arrow-more">
                    <font-awesome-icon icon="long-arrow-alt-down"></font-awesome-icon>
                </div>


            </div>





            <!-- DEPENDENT DETAIL  -->
            <template>
                <div v-if="showdependent === 'yes'">
                    <!-- MEMBER NAME -->
                    <div id="member-name-dependents-box">
                        <b-row align-v="center" align-h="center" class="name-container max-100">
                            <div class="member-name-dependents">
                                {{ member.member_name_f }}
                                {{ member.member_name_m }}
                                {{ member.member_name_l }}
                            </div>
                        </b-row>
                    </div>

                    <div>
                        <div id="dependent-container">
                            <div class="dependent-plate-data">
                                <div v-for="dependent in dependents" v-bind:key="dependent.dependent_code">
                                    <div class="dependent-box">
                                        <div class="dependent-name-bar" align-v="center">
                                            <b>{{ dependent.dependent_name_f }} {{ dependent.dependent_name_m }} {{ dependent.dependent_name_l }}</b>
                                            <span class="inv">.</span>-<span class="inv">.</span>{{ dependent.dependent_relation }}
                                        </div>
                                        <div class="dependent-data">
                                            <div class="ghost-dep">SSN:<span class="inv">................</span>{{ dependent.dependent_ss_no }}</div>
                                            <div>Date of Birth:<span class="inv">......</span>{{ dependent.dependent_date_of_birth }}</div>
                                            <div class="ghost-dep">Gender:<span class="inv">.............</span>{{ dependent.gender }}</div>
                                            <div>Dependent End Date:<span class="inv">.</span>{{ dependent.dependent_end_date }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div id="dependent-hidden-div"></div>
                            </div>
                        </div>
                    <div id="dependent-list-arrow-more">
                        <font-awesome-icon icon="long-arrow-alt-down"></font-awesome-icon>
                    </div>
                </div>

                </div>
            </template>

            <!-- CLAIMS DETAIL  -->
            <template>
                <div v-if="showclaims === 'yes'">
                    <!-- MEMBER NAME -->
                    <div id="member-name-claims-box">
                        <b-row align-v="center" align-h="center" class="name-container max-100">
                            <div class="member-name-dependents">
                                {{ member.member_name_f }}
                                {{ member.member_name_m }}
                                {{ member.member_name_l }}
                            </div>
                        </b-row>
                    </div>
                    <div id="claims-container">
                        <div class="claim-section">
                            <div class="claim" v-for="(claim, obj) in claims" v-bind:key="obj">
                                <div class="claim-bold claim-billing-name">{{ claim.billing_name }}</div>
                                <div><span class="claim-bold">Claim No:<span class="inv">..</span></span>{{ claim.claim_no }}<span class="inv">.</span>:<span class="inv">.</span><span class="claim-gray-italic">{{ claim.claim_type_desc }}</span></div>
                                <div :class="claim.claim_status_theme">
                                    <div class="claim-status" align-v="center">
                                        <span class="claim-bold">Status:</span>
                                        <span class="inv">.</span>
                                        <span>{{ claim.status_desc }}</span>
                                        <span v-if="claim.status_desc === 'DENIED'">
                                            <span class="inv">.</span>
                                            <span>-</span>
                                            <span class="inv">.</span>
                                            <span>{{ claim.denial_msg }}</span>
                                        </span>
                                    </div>
                                    <div class="claim-status-detail" v-if="claim.status_desc === 'PAID'">
                                        <div>
                                            <span class="claim-bold">Check No:</span>
                                            <span class="inv">.</span>
                                            <span>{{ claim.pymt_check_no }}</span>
                                        </div>
                                        <!-- <span class="inv">.</span> -->
                                        <div>
                                            <span class="claim-bold">Check Date:</span>
                                            <span class="inv">.</span>
                                            <span>{{ claim.pymt_check_date }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div align-v="center"><span class="claim-bold">Patient:</span><span class="inv">.</span>{{ claim.patient_name }}</div>
                                <div  align-v="center">
                                    <span class="claim-bold">DOS:</span>
                                    <span class="inv">.</span>
                                    <span>{{ claim.date_of_service_from }}</span>
                                    <span class="inv">.</span>
                                    <span>-</span>
                                    <span class="inv">.</span>
                                    <span>{{ claim.date_of_service_to }}</span>
                                </div>
                                <div><span class="claim-bold">Bill Amt:</span><span class="inv">.</span>{{ claim.total_actual }}</div>
                                <div><span class="claim-bold">Denied Amt:</span><span class="inv">.</span>{{ claim.total_denied }}</div>
                                <div><span class="claim-bold">Paid Amt:</span><span class="inv">.</span>{{ claim.total_payable }}</div>
                            </div>

                            <div id="claims-hidden-div"></div>
                        </div>
                    </div>
                    <div id="claims-list-arrow-more">
                        <font-awesome-icon icon="long-arrow-alt-down"></font-awesome-icon>
                    </div>

                </div>
            </template>


            <!-- BENEFITS DETAIL  -->
            <template>
                <div id="benefits" v-if="showbenefits === 'yes'">
                    <!-- MEMBER NAME -->
                    <div id="member-name-benefits-box">
                        <b-row align-v="center" align-h="center" class="name-container max-100">
                            <div class="member-name-dependents">
                                {{ member.member_name_f }}
                                {{ member.member_name_m }}
                                {{ member.member_name_l }}
                            </div>
                        </b-row>
                    </div>
                    <div v-for="(benefityear, obj) in benefityears" v-bind:key="obj">
                        <b-row align-v="center" class="benefit-year-bar" :benefityear-value="benefityear.year">
                            <div class="benefit-year">{{ benefityear.year }}</div>
                            <div v-if="benefityear.expanded === 'yes'" class="benefit-collapse" @click.prevent="benefitsShowingChange">
                                <font-awesome-icon icon="caret-down"></font-awesome-icon>
                            </div>
                            <div v-if="benefityear.expanded === 'no'" class="benefit-expand" @click.prevent="benefitsShowingChange">
                                <font-awesome-icon icon="caret-right"></font-awesome-icon>
                            </div>
                        </b-row>
                        <div v-if="benefityear.expanded === 'yes'">
                            <b-row class="benefit-details-header">
                                <span class="bdh-1">BENEFIT</span>
                                <span class="bdh-2">ALLOWED</span>
                                <span class="bdh-3">USED</span>
                                <span class="bdh-4">REMAINS</span>
                            </b-row>
                            <div v-for="(benefit, obj) in benefits" v-bind:key="obj">
                                <b-row  class="benefit-details" v-if="benefit.year === benefityear.year">
                                    <span class="bdh-1">{{ benefit.benefit_desc }}</span>
                                    <span class="bdh-2">{{ benefit.allowed_amount }}</span>
                                    <span class="bdh-3">{{ benefit.used_amount }}</span>
                                    <span class="bdh-4">{{ benefit.remaining_amount }}</span>
                                </b-row>
                            </div>
                            <div class="benefit-spacer"></div>
                        </div>
                    </div>
                </div>
            </template>


            <!-- NOTES -->
            <template>
                <div id="member-name-notes-box" v-if="showmembernotes === 'yes'">
                    <b-row align-v="center" align-h="center" class="name-container max-100">
                        <div class="member-name-dependents">
                            {{ member.member_name_f }}
                            {{ member.member_name_m }}
                            {{ member.member_name_l }}
                        </div>
                    </b-row>
                </div>
                <div id="notes" v-if="showmembernotes === 'yes'">
                    <div id="notes-list">
                        <div v-for="(membernote, obj) in membernotes" v-bind:key="obj">
                            <div class="membernote-header">
                                <span class="membernote-username">{{ membernote.user_id }}: </span>
                                <span>{{ membernote.entered_date }}</span>
                            </div>
                            <div class="membernote-nullackby" v-if="membernote.ack_by == null">Not Acknowledged</div>
                            <div class="membernote-ackby" v-if="membernote.ack_by != null">Acknowledged by {{ membernote.ack_by }}: {{ membernote.ack_date }}</div>
                            <b-row class="membernote-note">{{ membernote.note }}
                            </b-row>
                        </div>
                    </div>

                </div>
                <b-row class="notes-header" v-if="showmembernotes === 'yes'">
                    <b-link id="add-note" @click="$bvModal.show('notes-modal')">
                        <!-- <div class="add-note"> -->
                            ADD NOTE
                        <!-- </div> -->
                    </b-link>
                </b-row>

                <b-modal no-fade id="notes-modal">
                    <template v-slot:modal-header="">
                    </template>

                    <template v-slot:default="">
                        <div>
                            <b-form-textarea
                              id="notes-middle"
                              v-model="notetext"
                              rows="3"
                              max-rows="6"
                            ></b-form-textarea>

                          </div>
                     </template>

                    <template v-slot:modal-footer="">
                        <b-container fluid>
                        <a class="add-note-link" href="#" @click.prevent="noteAdd">
                            <div class="add-note-button">Add Note</div>
                        </a>
                        </b-container>

                    </template>

                </b-modal>

            </template>


            <!-- BOTTOM MEMBER MENU BAR  -->
            <template>
                <b-row id="member-bottom-menu" align-v="center" class="max-100" v-if="member">

                    <!-- MEMBER ICON -->
                    <b-col class="member-menu-col">
                        <div class="member-menu-selector-container">
                            <div class="member-menu-selector" v-show="showmember === 'yes'"></div>
                        </div>
                        <b-link id="get-member" @click.prevent="memberGet">
                            <b-row align-h="center" >
                                <b-img class="member-menu-img" fluid src="../assets/MemberPage_Member.png"></b-img>
                            </b-row>
                            <b-row class="member-bottom-menu-label" align-h="center">MEMBER</b-row>
                        </b-link>
                    </b-col>

                    <!-- FAMILY ICON -->
                    <b-col class="member-menu-col" align-h="center" v-if="hasdependent === 'yes'" >
                        <div class="member-menu-selector-container">
                            <div class="member-menu-selector" v-show="showdependent === 'yes'"></div>
                        </div>
                        <b-link id="get-dependents" @click.prevent="dependentsGet">
                            <b-row align-h="center" >
                                <b-img class="member-menu-img" fluid src="../assets/MemberPage_Dependents.png"></b-img>
                            </b-row>
                            <b-row class="member-bottom-menu-label" align-h="center">FAMILY</b-row>
                        </b-link>
                    </b-col>
                    <b-col class="member-menu-col" v-if="hasdependent === 'no'">
                        <div class="member-menu-selector-container">
                            <div class="member-menu-selector" v-show="showdependent === 'never'"></div>
                        </div>
                        <b-row  align-h="center">
                            <div id="no-dependents">
                                <b-img class="member-menu-img" fluid src="../assets/MemberPage_No_Dependents.png"></b-img>
                            </div>
                        </b-row>
                        <b-row class="member-bottom-menu-label no-dependents" align-h="center">FAMILY</b-row>
                    </b-col>

                    <!-- CLAIMS ICON -->
                    <b-col class="member-menu-col" v-if="hasclaims === 'yes' && user.claims_access === '1'">
                        <div class="member-menu-selector-container">
                            <div class="member-menu-selector" v-show="showclaims === 'yes'"></div>
                        </div>
                        <b-link id="member-claims" @click.prevent="claimsGet">
                            <b-row align-h="center">
                                <b-img class="member-menu-img" fluid src="../assets/MemberPage_Claims.png"></b-img>
                            </b-row>
                            <b-row class="member-bottom-menu-label" align-h="center">CLAIMS</b-row>
                        </b-link>
                    </b-col>
                    <b-col class="member-menu-col" v-if="hasclaims === 'no' || user.claims_access === '0'">
                        <div class="member-menu-selector-container">
                            <div class="member-menu-selector" v-show="showclaims === 'never'"></div>
                        </div>
                        <b-row align-h="center">
                            <div id="no-claims">
                                <b-img class="member-menu-img" fluid src="../assets/MemberPage_No_Claims.png"></b-img>
                            </div>
                        </b-row>
                        <b-row class="member-bottom-menu-label no-claims" align-h="center">CLAIMS</b-row>
                    </b-col>

                    <!-- BENEFITS ICON -->
                    <b-col class="member-menu-col" v-if="hasbenefits === 'yes'">
                        <div class="member-menu-selector-container">
                            <div class="member-menu-selector" v-show="showbenefits === 'yes'"></div>
                        </div>
                        <b-link id="member-benefits" @click.prevent="benefitsGet">
                            <b-row  align-h="center">
                                <b-img class="member-menu-img" fluid src="../assets/MemberPage_Benefits.png"></b-img>
                            </b-row>
                            <b-row class="member-bottom-menu-label" align-h="center">BENEFITS</b-row>
                        </b-link>
                    </b-col>
                    <b-col class="member-menu-col" v-if="hasbenefits === 'no'">
                        <div class="member-menu-selector-container">
                            <div class="member-menu-selector" v-show="showbenefits === 'never'"></div>
                        </div>
                        <b-row align-h="center">
                            <div id="no-benefits">
                                <b-img class="member-menu-img" fluid src="../assets/MemberPage_No_Benefits.png"></b-img>
                            </div>
                        </b-row>
                        <b-row class="member-bottom-menu-label no-benefits" align-h="center">BENEFITS</b-row>
                    </b-col>

                    <!-- NOTES ICON -->
                    <b-col class="member-menu-col">
                        <div class="member-menu-selector-container">
                            <div class="member-menu-selector" v-show="showmembernotes === 'yes'"></div>
                        </div>
                        <b-link id="member-notes" @click.prevent="memberNotesGet">
                            <b-row  align-h="center">
                                <b-img class="member-menu-img" fluid src="../assets/MemberPage_Notes.png"></b-img>
                            </b-row>
                            <b-row class="member-bottom-menu-label" align-h="center">NOTES</b-row>
                        </b-link>
                    </b-col>

                </b-row>

            </template>
        </div>
        <div class="footer">
        </div>

    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import { library } from '@fortawesome/fontawesome-svg-core'
    import { faHistory } from '@fortawesome/free-solid-svg-icons'
    import { faSearch } from '@fortawesome/free-solid-svg-icons'
    import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
    import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
    import { faLongArrowAltDown } from '@fortawesome/free-solid-svg-icons'
    import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
    import { faCameraRetro } from '@fortawesome/free-solid-svg-icons'

    library.add(faHistory, faSearch, faCaretDown, faCaretRight, faLongArrowAltDown, faInfoCircle, faCameraRetro)



    export default {
        name: 'members',

        components: {

        },

        computed: {
            ...mapGetters({
                user: 'auth/user',
                showsearch: 'members/showsearch',
                error: 'members/error',
                foundmembers: 'members/foundmembers',
                memberhistory: 'members/memberhistory',
                member_id: 'members/memberid',
                member: 'members/member',
                showmember: 'members/showmember',
                dependents: 'members/dependents',
                hasdependent: 'members/hasdependent',
                showdependent: 'members/showdependent',
                claims: 'members/claims',
                hasclaims: 'members/hasclaims',
                showclaims: 'members/showclaims',
                benefits: 'members/benefits',
                hasbenefits: 'members/hasbenefits',
                showbenefits: 'members/showbenefits',
                benefityears: 'members/benefityears',
                membernotes: 'members/membernotes',
                showmembernotes: 'members/showmembernotes',
                elementarray: 'members/elementarray'
            }),
        },

        data: function() {
            return {
                pagetitle: 'MemberSearch',

                form: {
                    searchedname: '',
                    searchedssn: '',
                },

                notetext: '',
                altidnew: true

            }

        },

        mounted: function() {
            // for when returning after new photo taken
            if(this.elementarray === '' && this.showmember === 'yes') {
                var el = document.getElementById('member-photo-box');
                if(el) {
                    setTimeout(function() {
                        var topPos = el.offsetTop;
                        document.getElementById('member-ind-container').scrollTop = topPos;

                    }, 300)
                }
            } else {
                this.setElementArray({
                    containerName: 'member-ind-container',
                    listArrowName: 'member-ind-list-arrow-more',
                    hiddenDivName: 'member-ind-hidden-div'
                })
                this.flashList();
                this.onScroll();
            }

        },

        methods: {
            ...mapActions({
                searchName: 'members/searchName',
                searchSSN: 'members/searchSSN',
                selectMember: 'members/selectMember',
                clearMemberHistory: 'members/clearMemberHistory',
                // checkMemberHistory: 'members/checkMemberHistory',
                setMemberHistory: 'members/setMemberHistory',
                setMenuTab: 'members/setMenuTab',
                setHideSearch: 'members/setHideSearch',
                getDependents: 'members/getDependents',
                getClaims: 'members/getClaims',
                getBenefits: 'members/getBenefits',
                changeBenefitsShowing: 'members/changeBenefitsShowing',
                getMemberNotes: 'members/getMemberNotes',
                addMemberNote: 'members/addMemberNote',
                setNoteText: 'members/setNoteText',
                setElementArray: 'members/setElementArray',
                turnOnLoadingMsg: 'loadingmsg/turnOnLoadingMsg',
                turnOffLoadingMsg: 'loadingmsg/turnOffLoadingMsg'
            }),

            flashList () {
                if(this.endOfListVisible()) {
                    var contname = document.getElementById(this.elementarray.containerName);
                    var larrname = document.getElementById(this.elementarray.listArrowName);
                    contname.classList.remove('flash');
                    larrname.classList.remove('flash');

                    setTimeout(function() {
                        if(contname && larrname) {
                            contname.classList.add('flash');
                            larrname.classList.add('flash');
                        }

                        setTimeout(function() {
                            if(contname && larrname) {
                                contname.classList.remove('flash');
                                larrname.classList.remove('flash');
                            }

                            setTimeout(function() {
                                if(contname && larrname) {
                                    contname.classList.add('flash');
                                    larrname.classList.add('flash');
                                }

                            },100)
                        },250)
                    },400)
                }
            },

            onScroll() {
                var el = window.document.getElementById(this.elementarray.containerName);
                if(el) {
                    el.addEventListener("scroll", () => this.endOfListVisible());

                }

            },

            endOfListVisible() {
                // console.log(element_array.hiddenDivName)
                var endoflist = document.getElementById(this.elementarray.hiddenDivName);

                var listarrow = document.getElementById(this.elementarray.listArrowName);


                if(endoflist && listarrow) {
                    endoflist = endoflist.getBoundingClientRect();
                    listarrow = listarrow.getBoundingClientRect();
                    // console.log(endoflist.bottom+' '+listarrow.bottom);
                    if((endoflist.bottom - 60) > listarrow.bottom) {
                        document.getElementById(this.elementarray.listArrowName).style.visibility = 'visible';
                        return true;
                    } else {
                        document.getElementById(this.elementarray.listArrowName).style.visibility = 'hidden';
                        return false;
                    }

                }

                return false;

            },


            submitName() {
                if(this.form.searchedname == '') {
                    // do nothing
                } else {
                    this.turnOnLoadingMsg().then(() => {

                        this.searchName(this.form.searchedname).then(() => {
                            this.form['searchedssn'] = '';
                            document.getElementById('ssnSearch').style.top = "8em";
                            document.getElementById('nameSearch').style.top = "7em";
                            this.turnOffLoadingMsg().then(() => {
                                if(this.error === '') {
                                    this.setElementArray({
                                        containerName: 'member-results-container',
                                        listArrowName: 'member-results-list-arrow-more',
                                        hiddenDivName: 'member-results-hidden-div'
                                    });
                                    this.onScroll();
                                    this.flashList();
                                }
                            })
                        })//.catch(e => { console.log(e); })


                    })

                }

            },

            submitSSN() {
                if(this.form.searchedssn == '') {
                    // do nothing
                } else {

                    this.turnOnLoadingMsg().then(() => {
                        this.searchSSN(this.form.searchedssn).then(() => {
                            this.form['searchedname'] = '';
                            this.turnOffLoadingMsg();

                        })//.catch(e => { console.log(e); })

                    })//.catch(e => { console.log(e); })
                }

            },

            memberSelect: function(event) {
                var memberid = event.target.parentNode.getAttribute('member-value');

                this.turnOnLoadingMsg().then(() => {

                    this.selectMember(memberid).then(() => {
                        this.form['searchedssn'] = '';
                        this.form['searchedname'] = '';
                        this.setHideSearch();
                        this.addMemberHistory();
                        this.setElementArray({
                            containerName: 'member-ind-container',
                            listArrowName: 'member-ind-list-arrow-more',
                            hiddenDivName: 'member-ind-hidden-div'
                        })
                        this.setMenuTab('SET_SHOWMEMBER').then(() => {
                            this.turnOffLoadingMsg().then(() => {
                                this.flashList();
                                this.onScroll();

                            });

                        });

                    })//.catch(e => { console.log(e); })

                })//.catch(e => { console.log(e); })
            },

            memberReSelect: function(event) {
                var memberid = event.target.parentNode.getAttribute('key-value');
                this.$bvModal.hide('search-history-modal');

                this.turnOnLoadingMsg().then(() => {

                    this.selectMember(memberid).then(() => {
                        this.form['searchedssn'] = '';
                        this.form['searchedname'] = '';
                        this.setHideSearch();
                        this.addMemberHistory();
                        this.setElementArray({
                            containerName: 'member-ind-container',
                            listArrowName: 'member-ind-list-arrow-more',
                            hiddenDivName: 'member-ind-hidden-div'
                        })
                        this.setMenuTab('SET_SHOWMEMBER').then(() => {
                        this.turnOffLoadingMsg().then(() => {
                            var pos = document.getElementById('member-ind-container');
                            pos.scrollTo(0,0);

                            this.flashList();
                            this.onScroll();

                            });

                        });

                    })//.catch(e => { console.log(e); })

                })//.catch(e => { console.log(e); })

            },

            addMemberHistory() {
                // var msg;
                var a = {};
                a = {
                    alt_id: this.member.alt_id,
                    member_name_l: this.member.member_name_l,
                    member_name_f: this.member.member_name_f,
                }

                // check if there is existing search history
                if(this.memberhistory.length > 0) {

                    // set flag
                    this.altidnew = true;

                    for (var key in this.memberhistory) {
                        // check if current member is already in search history
                        if(this.altidnew && this.memberhistory[key].alt_id === a.alt_id) {
                            this.altidnew = false;
                        }

                    }

                    // member is new
                    if(this.altidnew) {
                        this.memberhistory.reverse();
                        this.memberhistory.push(a);
                        this.memberhistory.reverse();

                    } else {
                        // member exists, remove from list
                        var idremove = this.member.alt_id;

                        var newarray = this.memberhistory.filter(function(item) {
                            return item.alt_id != idremove
                        })

                        this.setMemberHistory(newarray)

                        // add to top of list
                        this.memberhistory.reverse();
                        this.memberhistory.push(a);
                        this.memberhistory.reverse();

                    }

                    //reduce list size
                    this.memberhistory.length = Math.min(this.memberhistory.length, 10);

                    // msg = {
                    //     command: 'setCache',
                    //     name: '/membersearch',
                    //     value: this.memberhistory
                    // };
                    // this.sendSWMessage(msg);

                } else {
                   this.setMemberHistory([a]);

                   // msg = {
                   //     command: 'setCache',
                   //     name: '/membersearch',
                   //     value: this.memberhistory
                   // };
                   // this.sendSWMessage(msg);

                }
            },

            memberHistoryClear() {
                this.clearMemberHistory();
            },

            memberGet() {
                this.turnOnLoadingMsg().then(() => {

                    this.setMenuTab('SET_SHOWMEMBER').then(() => {
                        this.turnOffLoadingMsg().then(() => {
                            var pos = document.getElementById('member-ind-container');
                            pos.scrollTo(0,0);

                            this.setElementArray({
                                containerName: 'member-ind-container',
                                listArrowName: 'member-ind-list-arrow-more',
                                hiddenDivName: 'member-ind-hidden-div'
                            })
                            this.flashList();
                            this.onScroll();
                        })

                    })//.catch(e => { console.log(e); })

                })//.catch(e => { console.log(e); })

            },

            dependentsGet() {
                this.turnOnLoadingMsg().then(() => {

                    this.getDependents(this.member_id).then(() => {
                        this.setHideSearch();
                        this.setMenuTab('SET_SHOWDEPENDENT');
                        this.turnOffLoadingMsg().then(() => {
                            var pos = document.getElementById('dependent-container');
                            pos.scrollTo(0,0);

                            this.setElementArray({
                                containerName: 'dependent-container',
                                listArrowName: 'dependent-list-arrow-more',
                                hiddenDivName: 'dependent-hidden-div'
                            })

                            this.flashList();
                            this.onScroll();
                        })

                    })//.catch(e => { console.log(e); })

                })//.catch(e => { console.log(e); })
            },

            claimsGet() {
                this.turnOnLoadingMsg().then(() => {

                    this.getClaims(this.member_id).then(() => {
                        this.setHideSearch();
                        this.setMenuTab('SET_SHOWCLAIMS');
                        this.turnOffLoadingMsg().then(() => {
                            var pos = document.getElementById('claims-container');
                            pos.scrollTo(0,0);

                            this.setElementArray({
                                containerName: 'claims-container',
                                listArrowName: 'claims-list-arrow-more',
                                hiddenDivName: 'claims-hidden-div'
                            })
                            this.flashList();
                            this.onScroll();
                        })

                    })//.catch(e => { console.log(e); })

                })//.catch(e => { console.log(e); })

            },

            benefitsGet() {
                this.turnOnLoadingMsg().then(() => {

                    this.getBenefits(this.member_id).then(() => {
                        this.setHideSearch();
                        this.setMenuTab('SET_SHOWBENEFITS');
                        this.turnOffLoadingMsg();

                    })//.catch(e => { console.log(e); })

                })//.catch(e => { console.log(e); })

            },

            benefitsShowingChange: function(event) {
                var benefityear = event.target.parentNode.getAttribute('benefityear-value');

                this.changeBenefitsShowing(benefityear).then(() => {
                    window.scrollTo(0,0);
                })//.catch(e => { console.log(e); })

            },

            memberNotesGet() {
                this.turnOnLoadingMsg().then(() => {

                    this.getMemberNotes(this.member_id).then(() => {
                        this.setHideSearch();
                        this.setMenuTab('SET_SHOWMEMBERNOTES');
                        this.turnOffLoadingMsg().then(() => {
                            var pos = document.getElementById('notes-list');
                            pos.scrollTo(0,0);

                        });

                    })//.catch(e => { console.log(e); })

                })//.catch(e => { console.log(e); })

            },

            noteAdd() {
                if(this.notetext != '') {
                    this.turnOnLoadingMsg().then(() => {
                        this.setNoteText(this.notetext).then(() => {
                            this.addMemberNote(this.user.user_id).then(() => {

                            }).then(() => {
                                this.notetext = '';
                                this.$bvModal.hide('notes-modal');
                                this.memberNotesGet();

                            })//.catch(e => { console.log(e); })

                        })//.catch(e => { console.log(e); })

                    })//.catch(e => { console.log(e); })

                }

            },

            goPhoto() {
                if(this.$route.name != 'photo') {
                    this.turnOnLoadingMsg().then(() => {
                        this.$router.replace({
                            name: 'photo'
                        }).then(() => {
                            this.turnOffLoadingMsg();
                        })
                    })

                }

            }

            // sendSWMessage(msg) {
            //     if(navigator.serviceWorker){
            //         navigator.serviceWorker.controller.postMessage({msg});
            //     }
            // }



        }


    }
</script>

<style scoped>

    #fake-container {
        position: fixed;
        top: 0;
        margin: 0 0 0 0;
        padding: 0 0 0 0;
        min-width: 100%;
        max-width: 100%;
        min-height: 40vh;
        max-height: 40vh;
    }

    #member-banner {
        vertical-align: middle;
        border-style: none;
        min-height: 40vh;
        max-height: 40vh;
        min-width: 100%;
        float: right;
        z-index: 0;

    }

    #member-search-history {
        position: fixed;
        top: 0;
        right: 0;
        margin: 0 0 0 0;
        padding: 0 0 0 0;
        padding-right: 0.75em;
        transform: translateY(0.4em);
        font-size: 1.5em;
        font-weight: bold;
        color: white;
        width: 50vw;
        float: right;
        text-align: right;
        z-index: 1020;
        outline: none;
    }

    .clear-search-history-link {
        margin: auto;
        text-align: center;
        text-decoration: none;
        outline: none;
    }

    .clear-search-history-button {
        font-weight: bold;
        color: white;
        padding-top: 0.25em;
        padding-bottom: 0.25em;
        border-radius: .5em;
        background-color:  #0f70b7;
    }

    .content {
        background-color: #ededed;
        margin: 0 0 0 0;
        padding: 0 0 0 0;
        margin-top: 3.5em;
        max-width: 100%;
        height: 90vh;
    }

    #errormsg {
        position: relative;
        z-index: 100;
        margin-bottom: 2vh;
        color: red;
        font-weight: bold;
    }

    .max-100 {
        margin: 0 0 0 0;
        padding: 0 0 0 0;
        min-width: 100%;
        max-width: 100%;
    }
    #ssnSearch, #nameSearch {
        padding: 1vw;
        min-height: 3.5em;
        background-color: white;
    }

    #foundmembers-header {
        position: relative;
        z-index: 100;
        margin-top: 1em;
        text-align: center;
        color: white;
    }

    #member-results-container {
        position: relative;
        overflow-y: scroll;
        padding-top: 1em;
        margin-top: 1em;
        margin-left: 10vw;
        margin-right: 10vw;
        white-space: nowrap;
        min-height:  calc(100vh - 17em);
        max-height:  calc(100vh - 17em);
        z-index: 150;
    }

    #member-results-container.flash, #dependent-container.flash,
    #claims-container.flash, #member-ind-container.flash {
        border-bottom: 1px solid #0d2365;
    }

    #member-results-container.fade, #dependent-container.fade,
    #claims-container.fade, #member-ind-container.fade {
        opacity: 1;
        max-width: 90vw;
        border-bottom: 1px solid Gray;
        transition: border 0.2s;
    }

    #member-results-list {
        max-width: 80vw;
    }

    #member-results-list-arrow-more {
        max-width: 5vw;
        transform: translate(88vw,-1.5em);
        font-size: 2em;
        color: transparent;
    }

    #member-ind-list-arrow-more {
        position: absolute;
        top: calc(100vh - 4.5em);
        max-width: 5vw;
        transform: translateX(95vw);
        font-size: 2em;
        color: transparent;
    }

    #dependent-list-arrow-more, #claims-list-arrow-more {
        max-width: 5vw;
        transform: translate(92vw,0.25em);
        font-size: 2em;
        color: transparent;
    }


    #member-results-list-arrow-more.flash, #dependent-list-arrow-more.flash,
    #claims-list-arrow-more.flash, #member-ind-list-arrow-more.flash {
        color: #0d2365;
    }

    #member-results-list-arrow-more.fade, #dependent-list-arrow-more.fade,
    #claims-list-arrow-more.fade, #member-ind-list-arrow-more.fade {
        opacity: 1;
        color: DarkGray;
        transition: color 0.2s;
    }

    #member-results-hidden-div, #dependent-hidden-div,
    #claims-hidden-div, #member-ind-hidden-div {
        height: 1px;
        visibility: hidden;
    }

    .inv {
        color: transparent;
    }

    .custominput input[type=text] {
        margin: 0 0 0 0;
        width: 100%;
        min-height: 3em;
        outline: none;
        padding-right: 10vw;
    }

    .input-group {
        width: 90vw;
        transform: translateX(5vw);
    }

    .input-group-nest {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        outline: none !important;
    }

    button.searchbutton {
        font-size: 2em;
        width: 15vw;
        min-height: 100%;
        max-height: 100%;
        margin: 0 0 0 0;
        padding-right: 1.25em;
        width: 10vw;
        text-align: right;
        outline: none;
        border: none;
    }

    .btn {
        color: #0d2365;
        outline: none !important;
        box-shadow: none;
    }

    .btn:focus, .btn:active, .btn:hover {
        outline: none !important;
        box-shadow: none;
    }

    .buttons {
        margin-left: 5vw;
        margin-right: 5vw;
        margin-bottom: 10px;
        padding: 0 0 0 0;
    }

    .non-clickable {
        pointer-events: none;

    }

    .button-top-row {
        white-space: normal;
        pointer-events: none;
        margin: 0 0 0 0;
        padding: 0.25em;
    }

    .button-bottom-row {
        pointer-events: none;
        margin: 0 0 0 0;
        font-size: .75em;
        color: black;

    }

    .btn-secondary {
        background-color: white;
    }

    .btn-secondary:hover {
        background-color: #5a6268;
        color: white;
    }

    .button-bottom-row-red {
        margin: 0 0 0 0;
        font-size: .75em;
        color: red;
        pointer-events: none;
    }

    .button-bottom-row-black {
        margin: 0 0 0 0;
        padding: 0.25em;
        font-size: .75em;
        color: black;
        pointer-events: none;
    }

    #member-ind-container {
        position: absolute;
        top: 6.5em;
        overflow-y: scroll;
        max-width: 100vw;
        margin-left: 5vw;
        white-space: nowrap;
        min-height:  calc(100vh - 13em);
        max-height:  calc(100vh - 13em);
        z-index: 150;

    }

    #member-top-box, #member-address-box, #member-shop-box {
        position:relative;
        width: 90vw;
        border: 3px solid #e0e0e0;
        margin-bottom: 1em;
        background-color: white;
        z-index: 100;
    }

    #member-photo-box {
        position:relative;
        width: 90vw;
        border: 3px solid #e0e0e0;
        background-color: white;
        z-index: 100;
    }

    .name-container {
        color: white;
        height: 2em;
    }

    #member-photo-button {
        display: block;
        font-size: 1.75em;
        margin-top: 0.25em;
        margin-right: 0.5em;
        z-index: 110;
        position: absolute;
        right: 0;
    }

    .member-photo-container {
        margin: 0 0 0 0;
        padding: 0 0 0 0;
        background-color: white;
    }

    .member-photo {
        display: block;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .no-photo {
        display: block;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 10px;
        padding-bottom: 10px;

    }

    #member-card {
        position: relative;
        margin: 0 0 0 0;
        padding: 0 0 0 0;
        z-index: 9;
    }

    .member-plate-data {
        font-family: monospace;
        font-size: 1.1em;
        padding: 1em;
    }

    .member-name {
        text-align: center;
        width: 90%;
        font-size: 1.25em;
        font-weight: bold;
        border-bottom: 2px solid #e0e0e0;
    }

    .ghost {
        background-color: #0D619E;
        color: white;
    }

    .label-col {
        text-align: right;
        padding: 0;
    }

    .general-info-icon {
        padding: 0 0 0 0;
        margin: 0 0 0 0;
        font-size: 1.1em;
    }

    #member-general-info, #member-address-bar, #member-shop-bar {
        display: inline-flex;
        margin-left: 5%;
        max-width: 90%;
        min-width: 90%;
        font-size: 1.1em;
        border-bottom: 2px solid #e0e0e0;
        overflow-x: scroll;
    }

    #general-info-label, #address-label, #member-shop-label {
        display: inline-flex;
        max-width: 80%;
    }

    #member-address, #member-shop {
        font-family: monospace;
        font-size: 1.1em;
        width: 90%;
        margin: 5vw;
        background-color: white;
        color: black;
    }

    #member-name-box, #member-name-dependents-box, #member-name-claims-box, #member-name-notes-box, #member-name-benefits-box {
        position: fixed;
        top: 3.5em;
        height: 2.5em;
        width: 90vw;
        margin-left: 5vw;
        z-index: 100;
    }

    .member-name-dependents {
        text-align: center;
        width: 95%;
        font-size: 1.25em;
        font-weight: bold;
        padding-left: 0.5em;
        padding-right: 0.5em;
    }

    #dependent-container {
        position: relative;
        top: 3.5em;
        height: 100%;
        width: 90vw;
        padding: 5vw;
        margin-left: 5vw;
        min-height:  calc(100vh - 13em);
        max-height:  calc(100vh - 13em);
        overflow-y: scroll;
        z-index: 100;
    }

    .dependent-plate-data {
        margin: 0 0 0 0;
    }

    .dependent-box {
        border: 3px solid #e0e0e0;
        background-color: white;
        max-width: 80vw;
        margin-bottom: 1em;
        border-radius: 0.25em;
    }

    .dependent-name-bar {
        display: inline-flex;
        margin-left: 5%;
        width: 90%;
        font-size: 1.1em;
        border-bottom: 2px solid #e0e0e0;
    }

    .dependent-data {
        font-family: monospace;
        font-size: 1em;
        padding-top:1em;
        padding-bottom: 1em;
        padding-left: 5vw;
        padding-right: 5vw;
        color: black;
    }

    .address-icon, .shop-icon {
        min-height: 1.5em;
        min-width: 1.5em;
        padding: 0 0 0 0;
        margin: 0 0 0 0;
    }

    .address-icon {
        transform: translateX(-5vw);
    }

    .shop-icon {
        transform: translateX(-4vw);
    }

    #claims-container {
        position: relative;
        top: 3.5em;
        margin-left: 5vw;
        z-index: 150;
        width: 90vw;
        min-height: calc(100vh - 13em);
        max-height: calc(100vh - 13em);
        overflow-y: scroll;
    }

    .claim-section {
        max-width: 80vw;
        margin-left: 5vw;

    }

    .claim {
        font-size: 0.9em;
        padding: 1em;
        border: 3px solid #e0e0e0;
        margin-bottom: 1em;
        border-radius: 0.25em;
        background-color: white;
    }

    .claim-bold {
        font-weight: bold;
        font-family: sans-serif;
    }
    .claim-billing-name {
        word-wrap: break-word;
        font-family: sans-serif;
        font-size: 1.1em;
        border-bottom: 2px solid #e0e0e0;
        margin-bottom: 1em;
    }

    .claim-status-paid {
        padding-left: 1em;
        color: #29753A;
    }

    .claim-status-denied {
        padding-left: 1em;
        color: #A31712;
    }

    .claim-status-hold {
        padding-left: 1em;
        color: #AD6318;
    }

    .claim-status-completed {
        padding-left: 1em;
        color: #4718AD;
    }

    .claim-status-open {
        padding-left: 1em;
        color: #084EC7;
    }

    .claim-status {
        display: block;
        width: 100%;
    }

    .claim-status-detail {
        display: block;
        width: 100%;
        padding-left: 1em;
    }


    .claim-gray-italic {
        font-style: italic;
        font-weight: bold;
        color: Gray;
    }

    #benefits {
        position: absolute;
        top: 7em;
        width: 90vw;
        z-index: 100;
    }

    .benefit-year-bar {
        background-color: white;
        max-width: 90vw;
        margin-left: 5vw;
        margin-bottom: 1em;
        padding: 0 0 0 0;
        min-height: 2.5em;
        max-height: 2.5em;
        border: 3px solid #e0e0e0;
        vertical-align: middle;
    }

    .benefit-year {
        font-weight: bold;
        font-size: 1.5em;
        margin-left: 50%;
        display: inline-block;
        transform: translate(-50%,-0.1em);
    }

    .benefit-expand, .benefit-collapse {
        margin: 0 0 0 0;
        padding: 0 0 0 0;
        font-weight: bold;
        font-size: 1.9em;
        min-width: 25%;
    }

    .benefit-expand svg {
        float: right;
        pointer-events: none;

    }

    .benefit-collapse svg {
        float: right;
        pointer-events: none;
    }

    .benefit-details-header {
        font-family: monospace;
        font-size: 1em;
        font-weight: bold;
        max-width: 80vw;
        margin-left: 10vw;
        background-color: white;
        border-top: 3px solid #e0e0e0;
        border-left: 3px solid #e0e0e0;
        border-right: 3px solid #e0e0e0;

    }

    .benefit-details {
        font-family: monospace;
        font-size: 1em;
        max-width: 80vw;
        background-color: white;
        margin-left: 10vw;
        border-left: 3px solid #e0e0e0;
        border-right: 3px solid #e0e0e0;
    }

    .bdh-1 {
        min-width:25vw;
        padding-left: 0.5em;
        margin-right: 1em;

    }

    .bdh-2 {
        min-width: 16vw;
        margin-right: 1em;

    }

    .bdh-3 {
        min-width: 10vw;
        margin-right: 1em;

    }

    .bdh-4 {

    }

    .benefit-spacer {
        min-height: 1em;
        max-height: 1em;
        margin-left: 10vw;
        border-top: 3px solid #e0e0e0;

    }
    #notes {
        position: absolute;
        top: 7em;
        margin-bottom: 2em;
        min-height: calc(100vh - 17em);
        max-height: calc(100vh - 17em);
        background-color: white;
        width: 80vw;
        margin-left: 10vw;
        border: 3px solid #e0e0e0;
        z-index: 100;

    }

    #notes-list {
        padding-left: 1em;
        padding-right: 1em;
        min-height: calc(100vh - 18em);
        max-height: calc(100vh - 18em);
        overflow-y: scroll;
    }

    .notes-header {
        position:absolute;
        top: calc(100vh - 10em);
        margin: 0 0 0 0;
        padding: 0 0 0 0;
        min-height: 2.5em;
        max-height: 2.5em;
        width: 100%;
        z-index: 100;

    }

    .membernote-header {
        margin: 0 0 0 0;
        padding: 0 0 0 0;
        width: 100%;
    }

    .membernote-username {
        margin: 0 0 0 0;
        padding: 0 0 0 0;
        width: 100%;
        font-weight: bold;

    }

    .membernote-note {
        margin: 0 0 0 0;
        padding: 0 0 0 0;
        width: 100%;
        padding-left: 1em;
        padding-right: 1em;
        padding-bottom: 1em;
    }

    #add-note {
        margin-bottom: 6.5em;
        background-color: #0f70b7;
        color: white;
        font-weight: bold;
        margin: auto;
        padding-left: 1em;
        padding-right: 1em;
        padding-top: 0.25em;
        padding-bottom: 0.25em;
        border-radius: .5em;
        border: 2px solid white;
        text-decoration: none;
        outline: none;
    }

    .add-note-link {
        margin: auto;
        text-align: center;
        text-decoration: none;
        outline: none;
    }

    .add-note-button {
        font-weight: bold;
        color: white;
        padding-top: 0.25em;
        padding-bottom: 0.25em;
        border-radius: .5em;
        background-color:  #0f70b7;
    }

    .membernote-nullackby {
        margin: 0 0 0 0;
        padding: 0 0 0 0;
        width: 100%;
        color: Gray;
        padding-left: 1em;
        padding-right: 1em;

    }

    .membernote-ackby {
        margin: 0 0 0 0;
        padding: 0 0 0 0;
        width: 100%;
        color: Red;
        font-style: italic;
        padding-left: 1em;
        padding-right: 1em;

    }


    #member-bottom-menu {
        position: fixed;
        bottom: 1.5em;
        min-height: 3.5em;
        max-height: 3.5em;
        z-index: 100;
        background-color: #e0e0e0;
        color: black;
        border-top: 2px solid white;

    }

    #get-member, #member-claims, #no-claims, #get-dependents, #no-dependents, #member-benefits, #no-benefits, #member-notes {
        display: block;
        padding-top: 0.25em;
        min-height: 2.25em;
        max-height: 2.25em;
        outline: none;
        text-decoration: none;
    }

    .member-menu-col {
        min-height: 3.5em;
        max-height: 3.5em;
        min-width: 3.5em;
        outline: none;
    }

    .member-menu-selector-container {
        min-height: 0.25em;
        max-height: 0.25em;

    }

    .member-menu-selector {
        min-height: 0.25em;
        max-height: 0.25em;
        background-color: #0d2365;

    }

    .member-menu-img {
        min-height: 2em;
        max-height: 2em;
        min-width: 2em;
        outline: none;
    }

    .member-bottom-menu-label {
        font-size: .7em;
        color: black;
        text-decoration: none;
        outline: none;
        transform: translateY(-0.25em);
        margin-bottom: 0.5em;
    }

    /* make sure after .member-bottom-menu-label */
    .no-dependents, .no-benefits, .no-claims {
        color: #8f8f8f;
    }

    .footer {
        position: fixed;
        bottom: 0;
        margin: 0 0 0 0;
        padding: 0 0 0 0;
        min-width: 100%;
        min-height: 1.5em;
        border-top: 1px solid white;
        z-index: 100;
        background-color: #0d2365;
    }

</style>
